import React from 'react';
import {
  Col, Card, Form, FormGroup, FormControl, FormLabel,
  ButtonToolbar, Button, Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../css/Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password:'',
      signInMessage:"Enter credentials",
      bgColor: "#C7C7C7",
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  onChange(event, naturalValue) {
    const { name, value: textValue } = event.target;
    let value = naturalValue === undefined ? textValue : naturalValue;
    this.setState({
      [name] : value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const apiEndpoint = window.ENV.UI_AUTH_ENDPOINT;
    const { userName } = this.state;
    const { password } = this.state;
    
    try {
      const response = await axios.post(`${apiEndpoint}/signin`, {
        username: userName,
        password: password,
      });
      const { signedIn, username, role } = response.data;
      const userInfo = {
        signedIn,
        username,
        role
      }
      if(!signedIn||!username||!role) {
        this.setState({
          signInMessage: "Invalid username or password!",
          bgColor: "#FFA7A7",
        })
      } else {
        this.setState({
          signInMessage: "Sign in success!",
          bgColor: "#B9F491",
        })
      }
      const { onUserLogin } = this.props;
      onUserLogin(userInfo);
    } catch(error) {
      console.log(error);
      this.setState({
        signInMessage: "Invalid username or password!",
        bgColor: "#FFA7A7",
      })
    }
  }

  async onClear(e) {
    e.preventDefault();
    this.setState(
      { 
        userName: '',
        password: '',
      }
    );
  }
    

  render() {
    const { userName } = this.state;
    const { password } = this.state;
    return (
      <React.Fragment>
        <div className="pt-5">
          <div className="status-bar" style={{backgroundColor: this.state.bgColor}}>
            <h5>{this.state.signInMessage}</h5>
          </div>
          <Form horizontal="true" onSubmit={this.handleSubmit}>
            <FormGroup className="mb-3" controlId="userName">
              <FormLabel>User Name</FormLabel>
              <FormControl 
                type="text" 
                placeholder="Enter Username" 
                onChange={this.onChange}
                name="userName"
                value={userName}
              />
            </FormGroup>
            <FormGroup className="mb-3" controlId="password">
              <FormLabel>Password</FormLabel>
              <FormControl 
                type="password" 
                placeholder="Enter password" 
                onChange={this.onChange}
                name="password"
                value={password}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit">Submit</Button>
              {'   '}
              <Button onClick={this.onClear}> X </Button>
              {'   '}
              <Link to="/main">
                <Button>Back</Button>
              </Link>
            </FormGroup>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;

