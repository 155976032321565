import React from 'react';
import { Link } from 'react-router-dom';
import '../css/bootstrap.min.css';


export default function Comp_Footer() {
  return (
    <div className="footer bg-dark">
        <br />
    </div>
  );
}