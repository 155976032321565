import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, NavLink } from 'react-router-dom';
import {
  Row, Col, ListGroup,ListGroupItem, Button,
} from 'react-bootstrap';

import withRouter from '../withRouter.jsx';

const EntryItem = withRouter(({ entry, location: { search }, saveState, deleteEntry, prioritizeEntry, deprioritizeEntry, userInfo }) => {
  const selectLocation = { pathname: `/entries/${entry.id}`, search };
  const WAUrl = "https://wa.me/601125829096?text=" + encodeURIComponent(`Hi, I am interest in the post ${entry.model} RM${entry.price}, with mileage ${entry.mileage} km: http://p2pauto.com.my/report/${entry.id}`);

  return (
    <ListGroupItem>
      <div className="list-entry">
        <div className="float-start px-3" style={{ "height":"100%", "width":"40%" }} >
          <LazyLoadImage
            src={`/images/${ entry._id }/${ entry._id }_main.png`}
            style={{ "maxWidth":"100%", "maxHeight":"100%" ,"display":"block", "margin":"auto" }}
            onError={
              (e)=>{ if (e.target.src !== `/images/${ entry._id }/${ entry._id }_main.png`) 
                { 
                  e.target.onerror = null; 
                  e.target.src="images/defaults/null.png"; 
                } 
              }
            }
          />
        </div>
        <div className="float-start px-5" style={{ "height":"100%", "width":"60%" }} >
          <b><h2 className="main-list-item-info-div-title" >{entry.model}</h2></b>
          <h4 className="main-list-item-info-div-details" >RM {entry.price.toLocaleString("en-US")}</h4>
          <h4 className="main-list-item-info-div-details" >{entry.mileage.toLocaleString("en-US")} KM {entry.year}</h4>
          <div className="list-entry-nav">
            <a href={`${WAUrl}`} className="nav-link">
              <Button className="float-end m-1 push_button btn-lg" onClick={() => saveState()}>Contact us</Button>
            </a>
            <Link to={`/report/${entry.id}`} className="main-list-link">
              <Button className="float-end m-1 push_button btn-lg" onClick={() => saveState()}>View</Button>
            </Link>
          </div>
          {userInfo.role === "admin" ?
            <Link to={`/edit/${entry.id}`} className="main-list-link">
              <Button className="float-end m-1 push_button" onClick={() => saveState()}>Edit</Button>
            </Link>
            :
            <></>
          }
          {userInfo.role === "admin" ? <Button className="float-end m-1 push_button" onClick={() => deleteEntry(entry.id,entry._id)}>Delete</Button> : <></>}
          {userInfo.role === "admin" ? <Button className="float-end m-1 push_button" onClick={() => prioritizeEntry(entry.id,entry._id)}>{'\u2191'}</Button> : <></>}
          {userInfo.role === "admin" && entry.oldpriority !== null? <Button className="float-end m-1 push_button" onClick={() => deprioritizeEntry(entry.id,entry._id)}>{'\u2193'}</Button> : <></>}
        </div>
      </div>
    </ListGroupItem>
  );
});

export default function Car_List({ entries, saveState, deleteEntry, prioritizeEntry, deprioritizeEntry, userInfo}) {
  const entryGrids = entries.map(entry => (
    <EntryItem key={entry.id} entry={entry} saveState={saveState} deleteEntry={deleteEntry} deprioritizeEntry={deprioritizeEntry} prioritizeEntry={prioritizeEntry} userInfo={userInfo}/>
  ));
  
  return (
    <React.Fragment>
      <ListGroup className="main-list">
        {entryGrids}
      </ListGroup>
    </React.Fragment>
  );
}
  
