// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 35rem;
}

@media (min-width: 544px) {
  p {font-size:1.5rem;} /*1rem = 16px*/
  .carousel{margin-top:6em;}
}

@media (min-width: 992px) {
  p {font-size:1rem;} /*1rem = 16px*/
  .carousel{margin-top:2em;}
}

`, "",{"version":3,"sources":["webpack://./css/Comp_Main_Carousel.css"],"names":[],"mappings":"AAAA;oDACoD;;AAEpD,wBAAwB;AACxB;EACE,mBAAmB;AACrB;;AAEA,0DAA0D;AAC1D;EACE,aAAa;AACf;;AAEA;EACE,GAAG,gBAAgB,CAAC,EAAE,cAAc;EACpC,UAAU,cAAc,CAAC;AAC3B;;AAEA;EACE,GAAG,cAAc,CAAC,EAAE,cAAc;EAClC,UAAU,cAAc,CAAC;AAC3B","sourcesContent":["/* CUSTOMIZE THE CAROUSEL\n-------------------------------------------------- */\n\n/* Carousel base class */\n.carousel {\n  margin-bottom: 4rem;\n}\n\n/* Declare heights because of positioning of img element */\n.carousel-item {\n  height: 35rem;\n}\n\n@media (min-width: 544px) {\n  p {font-size:1.5rem;} /*1rem = 16px*/\n  .carousel{margin-top:6em;}\n}\n\n@media (min-width: 992px) {\n  p {font-size:1rem;} /*1rem = 16px*/\n  .carousel{margin-top:2em;}\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
