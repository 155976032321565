import React from 'react';
import {Card, Row, Col, Container, Button} from 'react-bootstrap';
import Collapsible from 'react-collapsible';

const Steps_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Steps to Sell</h1>
            </Col>
        </Row>
    );
};

const WhyP2PAuto_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Why Choose P2PAUTO?</h1>
            </Col>
        </Row>
    );
};

const Fees_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Fees</h1>
            </Col>
        </Row>
    );
};

export default function Comp_Sellers_Guide() {
    return (
        <div className="guide-section">
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
            <p style={{"color":"red"}} className="pt-2 mt-1 display-8 lh-1 text-start">Please note: Sellers will be required to present their cars to us at our <a href="https://maps.app.goo.gl/oxQxd7mtctyrdNWGA">inspection venue</a></p>
                <Collapsible trigger={<Steps_Header />}>
                    <hr />
                    <Row className="px-4 py-1 row-cols-lg-3 row-cols-sm-1 align-items-stretch" >
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">1. Contact Us</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start"><a href="https://wa.me/message/U3F5DYBMNB3QF1">Make an appointment</a> for inspection. Vehicle details are required in this step.</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">2. Inspection</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">Bring your vehicle to us for inspection on the agreed date & time</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">3. Decide on Selling Price</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">Pick a price to sell. We'll provide advice on the selling price of your car after inspection. However, the final decision is completely up to you</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">4. Advertise & Connect</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">We'll advertise your car and arrange appointments for buyers to view your car. You just need to bring your car to us before the appointment time</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">5. Deal</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">We'll arrange and guide you through all the necessary procedures and prepare the sales contract for you. You'll receive the payment once the ownership transfer is done</p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<WhyP2PAuto_Header />}>
                    <hr />
                    <p>1. Seller sell their cars at the true market value as cars are sold directly to the end consumers via P2PAUTO.</p>
                    <p>2. Minimize risks in the selling process as a trustworthy third party is involved, a legally registered company rather than an individual.</p>
                    <p>3. Hassle free selling process. Our one stop service provide assistance and guidance throughout the whole selling process.</p>
                    <p>4. We provide proper venues for car showcasing and meetings.</p>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<Fees_Header />}>
                    <hr />
                    <p>1.5% of the purchase price (with a min. of RM750) + 6% SST (subject to the fee).</p>
                </Collapsible>
            </Container>
        </div>
    );
}
