import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';

import C_ImageCluster from './C_ImageCluster.jsx';
import graphQLFetch from '../Utils/graphQLFetch.js';
import withRouter from '../withRouter.jsx';

class Report extends React.Component {
  constructor() {
    super();
    this.state = {
      entry: null,
      invalidFields: {},
      showingValidation: false,
    };
  }
  async loadData() {
    const query = `query entry($id: Int!) {
      entry(id: $id) {
        id _id model price ref_price mfg_year reg_year mileage transmission fuel rating description
      }
    }`;
    let { params: { id } } = this.props;
    id = parseInt(id);
    const data = await graphQLFetch(query, {id} );
    this.setState({ entry: data ? data.entry : {}, invalidFields: {} });
  }
  componentDidMount() {
    const { entry } = this.state;
    if (entry == null) this.loadData();
  }
  render() {
    const { entry } = this.state;
    if (entry == null) return null;
    
    const { entry: { model, price, ref_price, mfg_year, reg_year, mileage, transmission, fuel, rating, description }} = this.state;
    const { entry: { id, _id } } = this.state;
    const WAUrl = "https://wa.me/601125829096?text=" + encodeURIComponent(`Hi, I am interest in the post ${model} RM${price}, with mileage ${mileage} km: http://p2pauto.com.my/report/${id}`);
    if (id == null) {
      return <h3>{`Entry with ID ${id} not found.`}</h3>;
    }

    return (
      <div className="report px-5" style={{"minHeight":"95vh"}}>
        <div className="float-start px-3 pb-2">
          <h1>{model}</h1>
          <h3>{`RM ${price.toLocaleString("en-US")}`}</h3>
        </div>
        <div className="float-end px-3 pb-2">
          <a href={`${WAUrl}`} className="nav-link">
            <Button className="float-end mt-2 push_button btn-lg">Chat Now!</Button>
          </a>
        </div>
        <br />
        <div style={{"display":"inline-block", "width":"100%"}}>
          <div className="image-cluster float-start px-3">
            <C_ImageCluster image_id={_id} />
          </div>
          <div className="float-start px-3" style={{"width":"35%"}}>
            <div>
              <ul className="list-group">
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Manufacture Year"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${mfg_year}`}</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Registration Year"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${reg_year}`}</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Mileage"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${mileage.toLocaleString("en-US")} KM`}</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Transmission"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${transmission}`}</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Fuel"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${fuel}`}</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="float-start">
                    <p>{"Rating"}</p>
                  </div>
                  <div className="float-end">
                    <p>{`${rating}`}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="description float-start px-3">
            <h4>Description</h4>
            <hr />
            <p style={{"whiteSpace":"pre-wrap"}} >{`${description}`}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Report);
