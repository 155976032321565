import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';

export default class EntryAdd extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCoverImageChange = this.onCoverImageChange.bind(this);
    this.onImagesChange = this.onImagesChange.bind(this);
    this.imageListClick = this.imageListClick.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  };

  state = {
    selectedCoverImage : null,
    selectedTempImages : [],
    selectedImages : [],
    dragSource: null,
  };

  onCoverImageChange = event => {
    // Update the state
    this.setState({ selectedCoverImage: event.target.files[0] });
  };
  
  onImagesChange = event => {
    // Update the state
    const images = Array.from(event.target.files)
    this.setState({ selectedTempImages: images });
  };

  onImageAdd(e) {
    e.preventDefault();
    const {selectedTempImages, selectedImages} = this.state;
    console.log(selectedTempImages);
    if(selectedTempImages !== []) {
      const newSelectedImages = selectedImages.concat(selectedTempImages);
      this.setState({ selectedImages: newSelectedImages });
      document.getElementById("other_images").value = "";
      this.setState({ selectedTempImages: [] });
    }
  }



  handleSubmit(e) {
    e.preventDefault();
    const form = document.forms.entryAdd;
    const price_float = parseFloat(form.price.value);
    const ref_price_float = parseFloat(form.ref_price.value);
    const mfg_year_int = parseInt(form.mfg_year.value);
    const reg_year_int = parseInt(form.reg_year.value);
    const mileage_float = parseFloat(form.mileage.value);
    const entry = {
      model: form.model.value,
      price: price_float,
      ref_price: ref_price_float,
      mfg_year: mfg_year_int,
      reg_year: reg_year_int,
      mileage: mileage_float,
      transmission: form.transmission.value,
      fuel: form.fuel.value,
      rating: form.rating.value,
      description: form.description.value,
      cover_image: this.state.selectedCoverImage,
      images: this.state.selectedImages,
    };

    const { createEntry } = this.props;
    createEntry(entry);
    document.getElementById("entryAddForm").reset();
    this.setState({selectedCoverImage: null});
    this.setState({selectedImage : null});
    this.setState({selectedImages : []});
  }

  imageListClick(e) {
    e.preventDefault();
    const {selectedImages} = this.state;
    selectedImages.splice(document.getElementById(e.target.id).getAttribute("indexval"), 1);
    this.setState({ selectedImages: selectedImages });
  }

  handleDragStart(event) {
    const dragSource = parseInt(event.target.id.split("_").pop());
    this.setState({dragSource : dragSource});
  }
  handleDragEnd(event) {
    const {selectedImages} = this.state;
    const {dragSource} = this.state;
    const x = event.clientX;
    const y = event.clientY;
    var dragTarget = null;
    for(let i = 0; i < selectedImages.length; i++) {
      const bounds = document.getElementById(`other_images_div_${i}`).getBoundingClientRect();
      if(x < bounds.right && x > bounds.left && y > bounds.top && y < bounds.bottom) {
        dragTarget = i;
      }
    }
    if(dragTarget !== null){
      const dragSourceImage = selectedImages[dragSource];
      selectedImages.splice(dragSource, 1);
      selectedImages.splice(dragTarget, 0, dragSourceImage);
    }
    this.setState({dragSource: null});
    this.setState({ selectedImages: selectedImages });
  }

  render() {
    const {selectedImages} = this.state;
    const selectedImageList = selectedImages.map((object, index) => (
      <Col className="px-2" id={`other_images_div_${index}`} key={index} style={{"height":"15em", "border":"3px solid grey"}}>
        <p>{index + 1}</p>
        <img id={`other_images_${index}`} draggable onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} style={{"maxHeight":"10em","maxWidth":"90%", "display":"block"}} src={URL.createObjectURL(object)} />
        <button id={`removeImageButton_${index}`} indexval={index} style={{"height":"10%", "display":"block", "padding":"auto"}} onClick={(e) => this.imageListClick(e)}>x</button>
      </Col>
    ));
    return (
      <form id="entryAddForm" name="entryAdd" onSubmit={this.handleSubmit}>
        <input type="text" name="model" placeholder="Model" />
        <input type="text" name="price" placeholder="Price" />
        <input type="text" name="ref_price" placeholder="Ref. Price" />
        <input type="text" name="mfg_year" placeholder="Mfg. Year" />
        <input type="text" name="reg_year" placeholder="Reg. Year" />
        <input type="text" name="mileage" placeholder="Mileage" />
        <input type="text" name="transmission" placeholder="Transmission" />
        <input type="text" name="fuel" placeholder="Fuel" />
        <input type="text" name="rating" placeholder="Rating" />
        <br />
        <textarea name="description" maxLength="1000" wrap="hard" placeholder="Description" rows="4" cols="100" />
        <br />
        <label htmlFor="cover_image">Select Cover Image : </label>
        <input type="file" id="cover_image" name="cover_image" onChange={this.onCoverImageChange} accept="image/x-png,image/gif,image/jpeg"/>
        <br />
        <label htmlFor="other_images">Select Images : </label>
        <input type="file" multiple id="other_images" name="images" onChange={this.onImagesChange} accept="image/x-png,image/gif,image/jpeg"/>
        <button onClick={(e) => this.onImageAdd(e)}>{`Add image (${selectedImages.length} chosen)`}</button>
        <br />
        <Container style={{"display":"block"}}>
          <Row className="row-cols-lg-5 row-cols-sm-3 pt-2" style={{"border":"0"}}>
            {selectedImageList}
          </Row>
        </Container>
        <br />
        <button type="submit">Add</button>
      </form>
    );
  }
}

EntryAdd.propTypes = {
  createEntry: PropTypes.func.isRequired,
};

