import 'babel-polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import Page from './Page.jsx';

const root = ReactDOM.createRoot(document.getElementById('contents'));

const element = (
  <Router>
    <Page />
  </Router>
);

root.render(element);

if (module.hot) {
  module.hot.accept();
}
