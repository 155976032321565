import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Contents from './Contents.jsx';
import Comp_Header from './Comp_Header.jsx';
import Comp_Footer from './Comp_footer.jsx';

import "../css/Page.css";

export default function Page() {
  return (
    <div className="main">
      <Comp_Header />
      <br className="page-padding" />
      <div style={{"display":"block"}}>
        <Contents />
      </div>
      <Comp_Footer />
    </div>
  );
}
