import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {Row, Col, Container, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Comp_Main_Carousel from './Comp_Main_Carousel.jsx';

export default function Main() {
  return (
    <div>
      <Comp_Main_Carousel />
      <Container className="px-4 py-5" id="custom-cards">
      <h2 className="pb-2 border-bottom">Get Started</h2>
        <Row className="row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          <Col>
            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ "backgroundImage":"url(../../img/Background.png)"}}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Seller's Guide</h3>
                <small className="pt-2 mt-2 mb-4 display-8 lh-1">Always in the know about what you sell</small>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <Link to="/guide/seller" className="me-auto">
                      <Button style={{"backgroundColor":"#6E288A","borderColor":"#6E288A"}}>View Details</Button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col>
           <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ "backgroundImage":"url(../../img/Logo.png)"}}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Buyer's Guide</h3>
                <small className="pt-2 mt-2 mb-4 display-8 lh-1">Learn the benefits of trading on our platform</small>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <Link to="/guide/buyer" className="me-auto">
                      <Button style={{"backgroundColor":"#6E288A","borderColor":"#6E288A"}}>View Details</Button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col>
            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ "backgroundImage":"url(../../img/Logo.png)"}}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Our Role in the Trade</h3>
                <small className="pt-2 mt-2 mb-4 display-8 lh-1">Find out how P2PAUTO can help to keep your car trading safe and smooth</small>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <Link to="/guide/p2pauto" className="me-auto">
                      <Button style={{"backgroundColor":"#6E288A","borderColor":"#6E288A"}}>View Details</Button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}