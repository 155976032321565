// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown:hover {
    color:#868482;
}

@media (min-width: 544px) {
    p {font-size:1.5rem;} /*1rem = 16px*/
    .guide-section {margin-top: 10rem;}
}

@media (min-width: 992px) {
    p {font-size:1rem;} /*1rem = 16px*/
}
`, "",{"version":3,"sources":["webpack://./css/Guide.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,GAAG,gBAAgB,CAAC,EAAE,cAAc;IACpC,gBAAgB,iBAAiB,CAAC;AACtC;;AAEA;IACI,GAAG,cAAc,CAAC,EAAE,cAAc;AACtC","sourcesContent":[".dropdown:hover {\n    color:#868482;\n}\n\n@media (min-width: 544px) {\n    p {font-size:1.5rem;} /*1rem = 16px*/\n    .guide-section {margin-top: 10rem;}\n}\n\n@media (min-width: 992px) {\n    p {font-size:1rem;} /*1rem = 16px*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
