import React from 'react';
import withRouter from '../withRouter.jsx';
import {
  Form, FormGroup, FormControl, FormLabel, Button
} from 'react-bootstrap';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      oldest: '',
      newest: '',
      lowest: '',
      highest: '',
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onClearFilter = this.onClearFilter.bind(this);
  }

  onChange(event, naturalValue) {
    const defaults = {
      oldest: '0',
      newest: '9999',
      lowest: '0',
      highest: '999999999',
    }
    const { name, value: textValue } = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    this.setState({
      [name] : value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { searchTerm } = this.state;
    var { oldest, newest } = this.state;
    var { lowest, highest } = this.state;
    const { activateFilter } = this.props;
    if(newest === '' || isNaN(newest)) {
      newest = '9999';
    }
    if(oldest === '' || isNaN(oldest)) {
      oldest = '0';
    }
    if(lowest === '' || isNaN(lowest)) {
      lowest = '0';
    }
    if(highest === '' || isNaN(highest)) {
      highest = '999999999';
    }
    oldest = parseInt(oldest);
    newest = parseInt(newest);
    lowest = parseInt(lowest);
    highest = parseInt(highest);
    activateFilter(searchTerm, oldest, newest, lowest, highest);
  }

  async onClearFilter(e) {
    e.preventDefault();
    this.setState(
      { searchTerm: '',
        oldest: '',
        newest: '',
        lowest: '',
        highest:'',
      }
    );
    const { activateFilter } = this.props;
    activateFilter('', 0, 9999, 0, 999999999);
  }
    

  render() {
    const { searchTerm } = this.state;
    const { oldest, newest } = this.state;
    const { lowest, highest } = this.state;
    return (
      <Form horizontal="true" onSubmit={this.handleSubmit} className="mx-3 pt-5">
        <FormGroup className="mb-3" controlId="searchTerm">
          <FormLabel>Model</FormLabel>
          <FormControl 
            type="text"
            placeholder="Enter to search" 
            onChange={this.onChange}
            name="searchTerm"
            value={searchTerm?searchTerm:''}
          />
        </FormGroup>
        <FormGroup className="mb-3" controlId="oldest">
          <FormLabel>From</FormLabel>
          <FormControl 
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="4"
            placeholder="Year" 
            onChange={this.onChange}
            name="oldest"
            value={oldest}
          />
        </FormGroup>
        <FormGroup className="mb-3" controlId="newest">
          <FormLabel>To</FormLabel>
          <FormControl 
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="4"
            placeholder="Year" 
            onChange={this.onChange}
            name="newest"
            value={newest}
          />
        </FormGroup>
        <FormGroup className="mb-3" controlId="lowest">
          <FormLabel>From</FormLabel>
          <FormControl 
           type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="Lowest Price" 
            onChange={this.onChange}
            name="lowest"
            value={lowest}
          />
        </FormGroup>
        <FormGroup className="mb-3" controlId="highest">
        <FormLabel>To</FormLabel>
          <FormControl 
            type="text"
            inputMode="numeric"
            pattern="[0-9]*" 
            placeholder="Highest Price" 
            onChange={this.onChange}
            name="highest"
            value={highest}
          />
        </FormGroup>
        <FormGroup>
          <Button className="push_button" type="submit">Apply</Button>
          {'   '}
          <Button className="push_button" onClick={this.onClearFilter}> ↻ </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default withRouter(Search);
