import React from 'react';
import { pick } from 'lodash';
import axios from 'axios';
import {
  Row, Col, ListGroup,ListGroupItem, Button,
} from 'react-bootstrap';


import Search from './Search.jsx';
import Car_List from './Car_List.jsx';
import graphQLFetch from '../Utils/graphQLFetch.js';
import withRouter from '../withRouter.jsx';
import Paginate from './Paginate.jsx';
import EntryAdd from './EntryAdd.jsx';
import '../../css/sidebar.css'

/* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
function openNav() {
  document.getElementById("searchBar").style.width = "300px";
}

/* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
function closeNav() {
  document.getElementById("searchBar").style.width = "0px";
}

class Listing extends React.Component {
  constructor(props) {
    super(props);
    const { saved_state } = this.props;
    this.state = saved_state;
    this.createEntry = this.createEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.prioritizeEntry = this.prioritizeEntry.bind(this);
    this.deprioritizeEntry = this.deprioritizeEntry.bind(this);
    this.activateFilter = this.activateFilter.bind(this);
    this.saveState = this.saveState.bind(this);
    this.paginationClicked = this.paginationClicked.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {     
    const { searchTerm: prevSearchTerm, oldest: prevOldest, newest: prevNewest, lowest:prevLowest, highest:prevHighest, activePage: prevPage} = prevState;
    const { searchTerm, oldest, newest, lowest, highest } = this.state;
    const { activePage } = this.state;
    const { location: { search: prevSearch } } = prevProps;
    const { location: { search } } = this.props;
    if ((prevSearch !== search) || (prevSearchTerm !== searchTerm) || (prevOldest !== oldest) || (prevNewest !== newest) || (prevLowest !== lowest) || (prevHighest !== highest) || (prevPage !== activePage)) {
      this.loadData();
    }
  }

  async loadData() {

    const { searchTerm } = this.state;
    const { oldest, newest } = this.state;
    const { lowest, highest } = this.state;
    const { activePage } = this.state;
    const query = `query entryList(
      $search: String
      $yearMin: Int
      $yearMax: Int
      $priceMin: Float
      $priceMax: Float
      $page: Int
    ) {
      entryList(
        search: $search
        yearMin: $yearMin
        yearMax: $yearMax
        priceMin: $priceMin
        priceMax: $priceMax
        page: $page
      ) {
        entries {
          _id id model price mfg_year mileage oldpriority priority
        }
        pages
      }
    }`;

    const data = await graphQLFetch(query, { search: searchTerm, yearMin: oldest, yearMax: newest, priceMin: lowest, priceMax: highest, page: activePage});
    if (data) {
      this.setState({ entries: data.entryList.entries, pageCount: data.entryList.pages });
    }
  }

  async createEntry(entry) {
    const forApi = pick(entry, ["model", "price", "ref_price", "mfg_year", "reg_year", "mileage", "transmission", "fuel", "rating", "description"]);
    const query = `mutation entryAdd($forApi: EntryInputs!) {
      entryAdd(entry: $forApi) {
        id
        _id
      }    
    }`;
    const data = await graphQLFetch(query, { forApi });
    const formData = new FormData();
    formData.append(
      "myFile",
      entry.cover_image,
      `${data.entryAdd._id}_main`,
    );
    const filesAmount = entry.images.length;
    for ( let i = 0; i < filesAmount; i++ ){
      const img_filename = (i < 10)? `${data.entryAdd._id}_0${i}`:`${data.entryAdd._id}_${i}`;
      formData.append(
        "otherFiles",
        entry.images[i],
        img_filename,
      );
    }
    try {
      const result = await axios.post("/uploadFile", formData);
    } catch(error){
      console.error(error.response.data);
    }
    if(data) {
      this.loadData();
    }
    
  }

  async deleteEntry(id, _id) {
    const query = `mutation entryDelete($id: Int!) {
      entryDelete(id: $id)
    }`;
    const data = await graphQLFetch(query, { id });
    const token = data.entryDelete;
    this.loadData();
  }

  async prioritizeEntry(id, _id) {
    const query = `mutation entryPrioritize($id: Int!) {
      entryPrioritize(id: $id)
    }`;
    const data = await graphQLFetch(query, { id });
    this.loadData();
  }

  async deprioritizeEntry(id, _id) {
    const query = `mutation entryDeprioritize($id: Int!) {
      entryDeprioritize(id: $id)
    }`;
    const data = await graphQLFetch(query, { id });
    this.loadData();
  }

  activateFilter(term, filter_oldest, filter_newest, filter_lowest, filter_highest) {
    this.setState({
      searchTerm: term,
      oldest: filter_oldest,
      newest: filter_newest,
      lowest: filter_lowest,
      highest: filter_highest,
      activePage: 0,
    })    
  }

  saveState() {
    const { updateDisplayState } = this.props;
    const state = this.state;
    updateDisplayState(state);
  }

  paginationClicked = (activePage) => {
    this.setState({ activePage: activePage });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  render() {
    const { entries, pageCount } = this.state;
    const { match } = this.props;
    const { userInfo } = this.props;
    return (
      <div className="listing" >
        <div>
          <div id="searchBar" className="sidebar">
          <Button className="float-end btn-lg" style={{"color":"#ffffff", "backgroundColor":"transparent", "outline":"none", "border":"none"}} onClick={closeNav}>X</Button>
            <Search activateFilter={this.activateFilter}/>
          </div>
          <div className="pt-5">
            <Button className="push_button filter-button btn-lg" onClick={openNav}>
              <svg version="1.1" width="1rem" height="1rem" viewBox="0 0 100 100">
	              <path d="M 37.882 90 c -0.338 0 -0.676 -0.086 -0.981 -0.258 c -0.629 -0.354 -1.019 -1.02 -1.019 -1.742 V 45.354 L 3.923 3.208 C 3.464 2.604 3.388 1.791 3.726 1.11 S 4.758 0 5.517 0 h 78.966 c 0.76 0 1.453 0.43 1.791 1.11 s 0.262 1.493 -0.197 2.098 L 54.118 45.354 V 79.37 c 0 0.699 -0.365 1.348 -0.963 1.71 l -14.237 8.63 C 38.601 89.903 38.241 90 37.882 90 z M 9.543 4 l 29.932 39.474 c 0.264 0.348 0.406 0.772 0.406 1.208 v 39.767 l 10.236 -6.205 V 44.682 c 0 -0.437 0.143 -0.861 0.406 -1.208 L 80.457 4 H 9.543 z M 52.118 79.37 h 0.01 H 52.118 z" style={{"stroke": "none", "strokeWidth": "1", "strokeDasharray": "none", "strokeLinecap": "butt", "strokeLinejoin": "miter", "strokeMiterlimit": "10", "fill": "#FFFFFF", "fillRule": "nonzero", "opacity": "1"}} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
              </svg>
            </Button>
            <Car_List
              entries={entries}
              saveState={this.saveState}
              deleteEntry={this.deleteEntry}
              prioritizeEntry={this.prioritizeEntry}
              deprioritizeEntry={this.deprioritizeEntry}
              userInfo={ userInfo }
            />
          </div>
          <div className="d-flex justify-content-center pt-3">
            <Paginate className="pagination-lg"
              activePage={this.state.activePage}
              pageCount={Math.ceil(pageCount)}
              paginationClicked={this.paginationClicked}
            />
          </div>
        </div>
        <hr />
        <div>
          {userInfo.role === "admin" ? <EntryAdd createEntry={this.createEntry} /> : <br />}
        </div>
      </div>
    );
  }
}

export default withRouter(Listing);
