import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap';
import "../../css/C_ImageCluster.css";

const C_ScrollBar = ({ image_list, scrollBarClick }) => {
  return (
    <div className="container">
      <div className="row flex-nowrap overflow-x-auto" style={{"height":"10rem"}}>
      { image_list.map((image, index) => (
        <div className="col" key={index} style={{"height":"100%"}}>
          <button className="px-0" onClick={(e) => scrollBarClick(e.target.src, index)} style={{"height":"100%", "border":"0"}}>
            <LazyLoadImage src={ `/images/${image}` } style={{"height":"100%"}}/>
          </button>
        </div>
      ))}
      </div>
    </div>
  );
};
export default class C_ImageCluster extends React.Component {
  constructor(props) {
    super(props);
    this.scrollBarClick = this.scrollBarClick.bind(this);
    this.prevImage = this.prevImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.state = {
      active: "/images/defaults/Logo.png",
      image_list: ["/images/defaults/null.png"],
      index: 0
    };
  }

  async downloadImage() {
    try {
      const { image_id } = this.props;
      const params = {
        image_id,
      };
      const response = await axios.get(
        `/getImagesById/${ params.image_id }`,
      )
      const response_list = response.data;
      const maxIndex = response_list.length; 
      this.setState({ image_list: response_list });
      this.setState({ active: `/images/${ params.image_id }/${ params.image_id }_main.png`});
      this.setState({index: maxIndex});
    }
    catch (error) {
      console.log(error);
    }
  }

  scrollBarClick = (image, index) => {
    this.setState({ active: image });
    this.setState({ index: index });
  }
  
  componentDidMount() {
    this.downloadImage();
  }

  prevImage() {
    const { index } = this.state;
    const { image_list } = this.state;
    if (index > 0) {
      const newIndex = index - 1;
      const newImage = `/images/${image_list[newIndex]}`;
      this.setState({ index: newIndex});
      this.setState({ active: newImage });
    } else {
      this.setState({ index: image_list.length - 1});
      this.setState({ active: `/images/${image_list[image_list.length - 1]}` });
    }
  }

  nextImage() {
    const { index } = this.state;
    const { image_list } = this.state;
    if (index < (image_list.length - 1)) {
      const newIndex = index + 1;
      const newImage = `/images/${image_list[newIndex]}`;
      this.setState({ index: newIndex});
      this.setState({ active: newImage });
    } else {
      this.setState({ index: 0});
      this.setState({ active: `/images/${image_list[0]}` });
    }
  }
  
  render() {
    const { active } = this.state;
    const { image_list } = this.state;
    
    return (
      <React.Fragment>
        <div className="c-main-image" style={{"width":"100%", "position":"relative", "display":"flex", "justifyContent":"center"}}>
          <button className="push_button" onClick={this.prevImage} style={{"opacity":"0.8", "fontSize":"45px", "width":"10%", "height":"100%", "position":"absolute", "left":"0"}}>{'<'}</button>
          <img src={active} style={{"maxWidth":"100%", "maxHeight":"100%", "display":"inline-block", "margin":"auto"}}/>
          <button className="push_button" onClick={this.nextImage} style={{"opacity":"0.8", "fontSize":"45px", "width":"10%", "height":"100%", "position":"absolute", "right":"0"}}>{'>'}</button>
        </div>
        <hr />
        <div>
          <C_ScrollBar image_list={image_list} scrollBarClick={this.scrollBarClick}/>
        </div>
        <hr />
      </React.Fragment>
    )
  }
}
