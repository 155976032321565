// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The sidebar menu */
.sidebar {
  height: 100vh; /* 100% Full-height */
  overflow: hidden;
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #b5a575ca;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

  
  `, "",{"version":3,"sources":["webpack://./css/sidebar.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,aAAa,EAAE,qBAAqB;EACpC,gBAAgB;EAChB,QAAQ,EAAE,0CAA0C;EACpD,eAAe,EAAE,kBAAkB;EACnC,UAAU,EAAE,gBAAgB;EAC5B,MAAM;EACN,OAAO;EACP,2BAA2B;EAC3B,kBAAkB,EAAE,8BAA8B;EAClD,gBAAgB,EAAE,yDAAyD;AAC7E","sourcesContent":["/* The sidebar menu */\n.sidebar {\n  height: 100vh; /* 100% Full-height */\n  overflow: hidden;\n  width: 0; /* 0 width - change this with JavaScript */\n  position: fixed; /* Stay in place */\n  z-index: 1; /* Stay on top */\n  top: 0;\n  left: 0;\n  background-color: #b5a575ca;\n  overflow-x: hidden; /* Disable horizontal scroll */\n  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */\n}\n\n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
