import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';

import Main from './Main/Main.jsx';
import Guide from './Guide/Guide.jsx'
import GetStarted from './GetStarted/GetStarted.jsx';
import Login from './Login/Login.jsx';
import Listing from './Listing/Listing.jsx';
import Report from './Report/Report.jsx';
import EntryEdit from './Edit/EntryEdit.jsx';

const NotFound = () => <h1>Page Not Found</h1>;

export default class Contents extends React.Component {
  constructor() {
    super();
    this.state = {
      Listing_state:
      {
        entries: [],
        searchTerm: '',
        oldest: 0,
        newest: 9999,
        lowest: 0,
        highest: 999999999,
        activePage: 0,
        pageCount: '',
        currentUser: undefined,
      },
      UserInfo:
      {
        signedIn: false,
        username: '',
        role: '',
      },
    } 
    this.updateDisplayState = this.updateDisplayState.bind(this);
    this.onUserLogin = this.onUserLogin.bind(this);
  }
  
  async loadUserInfo() {
    const response = await axios.get("/verifyUser");
    var {signedIn, username, role} = response.data;
    if(!username || !role) {
      username = '';
      role = '';
    }
    const UserInfo = {
      signedIn,
      username,
      role,
    }
    this.setState({ UserInfo: UserInfo});
  }

  componentDidMount() {
    const data = this.loadUserInfo();
  }
  
  updateDisplayState(state) {
    this.setState({ Listing_state: state });
  }
  onUserLogin(userInfo) {
    this.setState({ UserInfo: userInfo });
  }
  render() {
    const { Listing_state, UserInfo } = this.state;
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/listing" replace />} />
        <Route path="/main" element={<Main />} />
        <Route path="/getstarted" element={<GetStarted />} />
        <Route path="/guide/:role" element={<Guide />} />
        <Route path="/listing" element={<Listing saved_state={ Listing_state } userInfo={ UserInfo } updateDisplayState={ this.updateDisplayState } />} />
        <Route path="/login" element={<Login />} />
        <Route path="/report/:id" element={<Report />} />
        <Route path="/edit/:id" element={<EntryEdit userInfo={ UserInfo }/>} />
        <Route path="/adminlogin12619" element={<Login onUserLogin={ this.onUserLogin } />} />
        <Route element={<NotFound />} />
      </Routes>
    );
  }
}
