import React from 'react';
import {Card, Row, Col, Container, Button} from 'react-bootstrap';
import Collapsible from 'react-collapsible';

const Steps_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Steps to Buy</h1>
            </Col>
        </Row>
    );
};

const WhyP2PAuto_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Why Choose P2PAUTO?</h1>
            </Col>
        </Row>
    );
};

const Fees_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Fees</h1>
            </Col>
        </Row>
    );
};

export default function Comp_Buyers_Guide() {
    return (
        <div className="guide-section">
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<Steps_Header />}>
                    <hr />
                    <Row className="px-4 py-1 row-cols-lg-3 row-cols-sm-1 align-items-stretch" >
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">1. Contact Us</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start"><a href="https://wa.me/message/U3F5DYBMNB3QF1">Tell us</a> which car you're interested in. We'll make an appointment with the seller and make arrangements for the car viewing.</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">2. View</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">Viewing of the car will be done at the venue provided by us to provide better inspection. You'll be guided through the inspection report of the car.</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-center">3. Deal</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">We will arrange all the necessary procedures, documents, and purchase agreements. Assistance will be provided if you wish to apply for bank loans.</p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<WhyP2PAuto_Header />}>
                    <hr />
                    <p>1. Buyers will have complete knowledge regarding the source and condition of the cars. Only inspected cars are allowed to be sold on P2PAUTO.</p>
                    <p>2. Transparent pricing. Buyers know exactly what they are paying for.</p>
                    <p>3. Minimize risks in the selling process as a trustworthy third party is involved, a legally registered company rather than an individual.</p>
                    <p>4. Hassle-free buying process. We provide guidance and assistance throughout the whole buying process including bank loan application.</p>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<Fees_Header />}>
                    <hr />
                    <p>1.5% of the purchase price (with a min. of RM750) + 6% SST (subject to the fee).</p>
                </Collapsible>
            </Container>
        </div>
    );
}
