// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu button {
  display: inline-block;
  width: 100%;
  color: white;
  text-align: center;
  padding: 14px;
}
`, "",{"version":3,"sources":["webpack://./css/C_ImageCluster.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".scrollmenu {\n  overflow: auto;\n  white-space: nowrap;\n}\n\n.scrollmenu button {\n  display: inline-block;\n  width: 100%;\n  color: white;\n  text-align: center;\n  padding: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
