import React from 'react';
import {Card, Row, Col, Container, Button} from 'react-bootstrap';
import Collapsible from 'react-collapsible';

const WhoAreWe_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Who Are We</h1>
            </Col>
        </Row>
    );
};

const Services_Header = () => {
    return (
        <Row className="dropdown">
            <Col className="mb-2 lh-1">
                <h1 className="text-start fw-bold">Services We Provide</h1>
            </Col>
        </Row>
    );
};

const OurPlatform_Header = () => {
    return (
      <Row className="dropdown">
          <Col className="mb-2 lh-1">
              <h1 className="text-start fw-bold">Our P2PAUTO Platform</h1>
          </Col>
      </Row>
    );
}

export default function Comp_AboutUs_Guide() {
    return (
        <div className="guide-section">
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<WhoAreWe_Header />}>
                    <hr />
                    <p>We are a company that is legally registered as Autoguy Sdn. Bhd. under the Companies Commission of Malaysia. We operate P2PAUTO online platform and provide one-stop offline services.</p>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<OurPlatform_Header />}>
                    <hr />
                    <p>An integrated P2P platform for used cars in Malaysia. All cars on the platform are traded using peer-to-peer model. In order to ensure a safe and transparent environment on the platform, Autoguy Sdn. Bhd. shall be appointed under the subscription of P2PAUTO one-stop service as the third party for every trade on P2PAUTO.</p>
                </Collapsible>
            </Container>
            <Container className="px-4 py-4 mt-5 text-shadow-1 bg-white">
                <Collapsible trigger={<Services_Header />}>
                    <hr />
                    <Row className="px-4 py-1 row-cols-lg-2 row-cols-sm-1 align-items-stretch" >
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-start">For Sellers</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">1. Advertisement for cars on P2PAUTO and several other platforms.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">2. Analysis on the cars’ market value.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">3. Preparation of documents.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">4. Communication with buyers.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">5. Arrangement for authorities' inspection and ownership transfer.</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="pt-1 mt-3 mb-2 lh-1">
                            <Card className="card-cover h-100 overflow-hidden rounded-4 shadow-lg" style={{ "backgroundColor":"#F6F4F2"}}>
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                    <h3 className="pt-3 mt-2 mb-2 display-8 lh-1 fw-bold text-start">For Buyers</h3>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">1. Inspection and analysis the car's condition with specialized tools.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">2. Proper venue for the buying process.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">3. Assistance in applying bank loans.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">4. Preparation of documents.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">5. Arrangement on all necessary procedures to purchase the car.</p>
                                    <p className="pt-2 mt-1 display-8 lh-1 text-start">6. Communication with sellers.</p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Collapsible>
            </Container>
        </div>
    );
}
