import React from 'react';
import axios from 'axios';
import store from '../Utils/store.js';
import graphQLFetch from '../Utils/graphQLFetch.js';
import { Link } from 'react-router-dom';
import withRouter from '../withRouter.jsx';

import {
  Col, Card, Form, FormGroup, FormControl, FormLabel,
  ButtonToolbar, Button, Alert,
} from 'react-bootstrap';

class EntryEdit extends React.Component {
  constructor() {
    super();
    const entry = store.initialData ? store.initialData.entry : null;
    delete store.initialData;
    this.state = {
      entry,
      invalidFields: {},
      selectedCoverImage : null,
      showingValidation: false,
      toastVisible: false,
      toastMessage: '',
      toastType: 'success',
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onValidityChange = this.onValidityChange.bind(this);
    this.onCoverImageChange = this.onCoverImageChange.bind(this);
  }

  async loadData() {
    const query = `query entry($id: Int!) {
      entry(id: $id) {
        id model price ref_price mfg_year reg_year mileage transmission fuel rating description
      }
    }`;
    let { params: { id } } = this.props;
    id = parseInt(id);
    const data = await graphQLFetch(query, {id} );
    this.setState({ entry: data ? data.entry : {}, invalidFields: {} });
  }
  
  componentDidMount() {
    const { entry } = this.state;
    if (entry == null) this.loadData();
  }
  
  componentDidUpdate(prevProps) {
    const { params: { id: prevId } } = prevProps;
    const { params: { id } } = this.props;
    if (id !== prevId) {
      this.loadData();
    }
  }

  onChange(event, naturalValue) {
    const { name, value: textValue } = event.target;
    let value = naturalValue === undefined ? textValue : naturalValue;
    if(event.target.name === "mfg_year" || event.target.name === "reg_year") {
      value = naturalValue === undefined ? parseInt(textValue) : naturalValue;
    } else if(event.target.name === "price" || event.target.name === "ref_price" || event.target.name === "mileage") {
      value = naturalValue === undefined ? parseFloat(textValue) : naturalValue;
    }
    this.setState(prevState => ({
      entry: { ...prevState.entry, [name]: value },
    }));
  }

  onValidityChange(event, valid) {
    const { name } = event.target;
    this.setState((prevState) => {
      const invalidFields = { ...prevState.invalidFields, [name]: !valid };
      if (valid) delete invalidFields[name];
      return { invalidFields };
    });
  }

  onCoverImageChange = event => {
    // Update the state
    this.setState({ selectedCoverImage: event.target.files[0] });
  };

  async handleSubmit(e) {
    e.preventDefault();
    const { entry } = this.state;
    const { selectedCoverImage } = this.state;
    const query = `mutation entryUpdate(
      $id: Int!
      $changes: EntryUpdateInputs!
    ) {
      entryUpdate(
        id: $id
        changes: $changes
      ) {
        _id id model price ref_price mfg_year reg_year mileage transmission fuel rating description
      }
    }`;

    const { _id, id, ...changes } = entry;
    const data = await graphQLFetch(query, { changes, id });
    if (selectedCoverImage) {
      const formData = new FormData();
      formData.append(
        "myFile",
        selectedCoverImage,
        `${data.entryUpdate._id}_main`,
      );
      try {
        const result = await axios.post("/uploadFile", formData);
      } catch(error){
        console.error(error.response.data);
      }
    }
    if (data) {
      this.setState({ entry: data.entryUpdate });
    }
  }
  
  render() {
    const { entry } = this.state;
    const { selectedCoverImage } = this.state;
    const { userInfo } = this.props;
    if (entry === null || userInfo.role !== "admin") return null;
    
    const { entry: { id } } = this.state;
    if (id === null) {
      return null;
    }
    const { entry: { model, price, ref_price } } = this.state;
    const { entry: { mfg_year, reg_year, mileage, transmission, fuel } } = this.state;
    const { entry: { rating, description } } = this.state;

    return (
      <Card className="pt-5">
        <Card.Header>
          <Card.Title>{`Editing entry: ${model}`}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form horizontal="true" onSubmit={this.handleSubmit}>
            <FormGroup>
              <Col sm={3}>Model</Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  name="model"
                  value={model}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Price</Col>
              <Col sm={9}>
                <FormControl
                  type="number"
                  name="price"
                  value={price}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Ref Price</Col>
              <Col sm={9}>
                <FormControl
                  type="number"
                  name="ref_price"
                  value={ref_price}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Mfg Year</Col>
              <Col sm={9}>
                <FormControl
                  type="number"
                  name="mfg_year"
                  value={mfg_year}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Reg Year</Col>
              <Col sm={9}>
                <FormControl
                  type="number"
                  name="reg_year"
                  value={reg_year}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Mileage</Col>
              <Col sm={9}>
                <FormControl
                  type="number"
                  name="mileage"
                  value={mileage}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Transmission</Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  name="transmission"
                  value={transmission}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Fuel</Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  name="fuel"
                  value={fuel}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Rating</Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  name="rating"
                  value={rating}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Description</Col>
              <Col sm={9}>
                <textarea 
                  name="description" 
                  maxLength="1000" 
                  wrap="hard" 
                  rows="4"
                  cols="100"
                  type="text"
                  name="description"
                  value={description}
                  onChange={this.onChange}
                  key={id}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3}>Select cover image</Col>
              <Col sm={6}>
                <input
                  name="cover_image"
                  type="file" 
                  onChange={this.onCoverImageChange}
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </Col>
              <div className="px-0" style={{"height":"15em", "border":"0", "display":"inline-block"}}>
                {
                  selectedCoverImage?
                    <img style={{"height":"90%", "display":"block"}} src={URL.createObjectURL(selectedCoverImage)} />
                    :
                    <br />
                }
              </div>
            </FormGroup>
            <FormGroup>
              <Col sm={6}>
                <ButtonToolbar>
                  <Button type="submit">Submit</Button>
                  <Link to="/Listing">
                    <Button>Back</Button>
                  </Link>
                </ButtonToolbar>
              </Col>
            </FormGroup>
          </Form>
        </Card.Body>
        <Card.Footer>
          <br />
        </Card.Footer>
      </Card>
    )
  }
}

export default withRouter(EntryEdit);
