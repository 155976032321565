import React from 'react';
import { Carousel } from 'react-bootstrap';

import '../../css/Comp_Main_Carousel.css';

export default function Comp_Main_Carousel() {
  return (
    <Carousel>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src="images/defaults/carousel_1.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src="images/defaults/carousel_2.png"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src="images/defaults/carousel_3.png"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src="images/defaults/carousel_4.png"
          alt="Fourth slide"
        />
      </Carousel.Item>
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src="images/defaults/carousel_5.png"
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}