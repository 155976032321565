// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-bar {
    width:70%;
    padding-left: 20px;
    padding-top: 10px;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
}

.status-bar h5 {
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./css/Login.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".status-bar {\n    width:70%;\n    padding-left: 20px;\n    padding-top: 10px;\n    border-radius: 10px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    height: 80px;\n}\n\n.status-bar h5 {\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
