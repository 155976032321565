import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export default function Paginate({pageCount, paginationClicked, activePage}) {
  const PageItems = Array.from(Array(pageCount).keys()).map(i => (
    <Pagination.Item className="page-object" active={activePage===i} key={i+1} onClick={(event) => {paginationClicked(i);}}>{i+1}</Pagination.Item>
  ));
  return (
    <Pagination>
      { activePage !== 0?
          <Pagination.Item key={0} onClick={(event) => {paginationClicked(activePage - 1);}}>prev</Pagination.Item>
          :
          <Pagination.Item key={0} disabled>prev</Pagination.Item>
      }
      {PageItems}
      { activePage !== pageCount-1?
          <Pagination.Item key={pageCount+1} onClick={(event) => {paginationClicked(activePage + 1);}}>next</Pagination.Item>
          :
          <Pagination.Item key={pageCount+1} disabled>next</Pagination.Item>
      }
    </Pagination>
  );
}