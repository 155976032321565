import React from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Container, Button} from 'react-bootstrap';

import Comp_Sellers_Guide from './Comp_Sellers_Guide.jsx';
import Comp_Buyers_Guide from './Comp_Buyers_Guide.jsx';
import Comp_AboutUs_Guide from './Comp_AboutUs_Guide.jsx';
import withRouter from '../withRouter.jsx';

import '../../css/Guide.css';

class Guide extends React.Component {
  constructor() {
    super();
  }
  renderSwitch(param) {
    switch(param) {
      case "seller":
        return <Comp_Sellers_Guide />;
      case "buyer":
        return <Comp_Buyers_Guide />;
      case "p2pauto":
        return <Comp_AboutUs_Guide />;
      default:
        return <h1>404 page not found</h1>;
    }
  }
  render() {
    let { params: { role } } = this.props;
    return(
      <div style={{"backgroundColor":"#F6F4F2", "minHeight":"100vh"}}>
        <br />
        {this.renderSwitch(role)};
      </div>
    );
  }
}

export default withRouter(Guide);